import { IUser } from "../users/types";

export enum AuthActionTypes {
  AUTH_ACCESS_TOKEN_SET = "@@auth/AUTH_ACCESS_TOKEN_SET",
  AUTH_FORGOT_PASSWORD_FAILED = "@@auth/AUTH_FORGOT_PASSWORD_FAILED",
  AUTH_FORGOT_PASSWORD_REQUESTED = "@@auth/AUTH_FORGOT_PASSWORD_REQUESTED",
  AUTH_FORGOT_PASSWORD_SUCCEEDED = "@@auth/AUTH_FORGOT_PASSWORD_SUCCEEDED",
  AUTH_LOGIN_FAILED = "@@auth/AUTH_LOGIN_FAILED",
  AUTH_LOGIN_NEW_PASSWORD_CHALLENGE_FAILED = "@@auth/AUTH_LOGIN_NEW_PASSWORD_CHALLENGE_FAILED",
  AUTH_LOGIN_NEW_PASSWORD_CHALLENGE_REQUESTED = "@@auth/AUTH_LOGIN_NEW_PASSWORD_CHALLENGE_REQUESTED",
  AUTH_LOGIN_NEW_PASSWORD_CHALLENGE_SUCCEEDED = "@@auth/AUTH_LOGIN_NEW_PASSWORD_CHALLENGE_SUCCEEDED",
  AUTH_LOGIN_NEW_PASSWORD_REQUIRED = "@@auth/AUTH_LOGIN_NEW_PASSWORD_REQUIRED",
  AUTH_LOGIN_REQUESTED = "@@auth/AUTH_LOGIN_REQUESTED",
  AUTH_LOGIN_SUCCEEDED = "@@auth/AUTH_LOGIN_SUCCEEDED",
  AUTH_LOGOUT_FAILED = "@@auth/AUTH_LOGOUT_FAILED",
  AUTH_LOGOUT_REQUESTED = "@@auth/AUTH_LOGOUT_REQUESTED",
  AUTH_LOGOUT_SUCCEEDED = "@@auth/AUTH_LOGOUT_SUCCEEDED",
  AUTH_RESET_PASSWORD_FAILED = "@@auth/AUTH_RESET_PASSWORD_FAILED",
  AUTH_RESET_PASSWORD_REQUESTED = "@@auth/AUTH_RESET_PASSWORD_REQUESTED",
  AUTH_RESET_PASSWORD_SUCCEEDED = "@@auth/AUTH_RESET_PASSWORD_SUCCEEDED",
  AUTH_SESSION_BOOTSTRAP_FAILED = "@@auth/AUTH_SESSION_BOOTSTRAP_FAILED",
  AUTH_SESSION_BOOTSTRAP_REQUESTED = "@@auth/AUTH_SESSION_BOOTSTRAP_REQUESTED",
  AUTH_SESSION_BOOTSTRAP_SUCCEEDED = "@@auth/AUTH_SESSION_BOOTSTRAP_SUCCEEDED",
  AUTH_SESSION_CHECK = "@@auth/AUTH_SESSION_CHECK",
  AUTH_SESSION_VALIDATED = "@@auth/AUTH_SESSION_VALIDATED"
}

export interface IAuthState {
  readonly email?: string;
  readonly error?: { [key: string]: any };
  readonly isLoggedIn: boolean;
  readonly isSessionBootstrapped: boolean;
  readonly isValid?: any;
  readonly newPasswordRequired?: boolean;
  readonly user?: IUser;
}
